/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import qs from 'qs';
import auth, { SESSION_EXPIRY } from '../../services/auth';
import api from '../../services/api';

export const initialState = {
  user: null,
  byPassUrl: null,
  loginStatus: 'idle',
  resetPasswordStatus: 'idle',
  resetPasswordModalOpen: false,
};

const MSG_SUCCESS_RESET_PASSWORD =
  'Success! An email has been sent containing instructions to reset your password.';
const MSG_ERROR = 'An unexpected error occurred. Please try again later.';
const MSG_ERROR_LOGIN_FAIL = 'Incorrect username or password.';
const MSG_ERROR_USER_PROFILE_FAIL = 'Failed to access user profile.';
const MSG_ERROR_NOT_FOUND = "We couldn't find this account.";

const API_LMI_AUTH = api.urls.auth;
const API_LMI_USER = api.urls.user;
const API_RESET_PASSWORD = api.urls.reset;
const LMI_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const BYPASS_KEY = process.env.REACT_APP_BY_PASS_KEY;

export const loginAsync = createAsyncThunk(
  'auth/login',
  async ({ username, password }, { rejectWithValue }) => {
    const url = API_LMI_AUTH;
    const data = {
      username,
      password,
      grant_type: 'password',
      client_id: LMI_PROJECT_ID,
    };

    try {
      const resToken = await axios.post(url, qs.stringify(data));
      const token = resToken.data.access_token;
      const resUser = await axios.get(API_LMI_USER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        token,
        expiry: new Date().getTime() + resToken.data.expires_in,
        user: resUser.data,
      };
    } catch (err) {
      const status = err ? err.status : null;
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          return rejectWithValue(MSG_ERROR_USER_PROFILE_FAIL);
        case StatusCodes.BAD_REQUEST:
          return rejectWithValue(MSG_ERROR_LOGIN_FAIL);
        default:
          return rejectWithValue(MSG_ERROR);
      }
    }
  }
);

export const loginWithEncryptedDataAsync = createAsyncThunk(
  'auth/encrypted-data-login',
  async ({ token, grantType }, { rejectWithValue }) => {
    const url = API_LMI_AUTH;
    const data = {
      grant_type: grantType,
      encrypted_data: token,
      client_id: LMI_PROJECT_ID,
    };

    try {
      const resToken = await axios.post(url, qs.stringify(data));
      const token = resToken.data.access_token;
      const resUser = await axios.get(API_LMI_USER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        token,
        expiry: new Date().getTime() + resToken.data.expires_in,
        user: resUser.data,
      };
    } catch (err) {
      const status = err ? err.status : null;
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          return rejectWithValue(MSG_ERROR_USER_PROFILE_FAIL);
        case StatusCodes.BAD_REQUEST:
          return rejectWithValue(MSG_ERROR_LOGIN_FAIL);
        default:
          return rejectWithValue(MSG_ERROR);
      }
    }
  }
);

export const fetchUserAsync = createAsyncThunk(
  'auth/fetch-user',
  async (token, { rejectWithValue }) => {
    try {
      const resUser = await axios.get(API_LMI_USER);
      return {
        user: resUser.data,
        token,
        expiry: localStorage.getItem(SESSION_EXPIRY),
      };
    } catch (err) {
      const status = err ? err.status : null;
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          return rejectWithValue(MSG_ERROR_USER_PROFILE_FAIL);
        case StatusCodes.BAD_REQUEST:
          return rejectWithValue(MSG_ERROR_LOGIN_FAIL);
        default:
          return rejectWithValue(MSG_ERROR);
      }
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/reset-password',
  async ({ username }, { rejectWithValue }) => {
    const url = `${API_RESET_PASSWORD}&userName=${username}`;
    try {
      await axios.post(url);
      return MSG_SUCCESS_RESET_PASSWORD;
    } catch (err) {
      const status = err ? err.status : null;
      switch (status) {
        case StatusCodes.NOT_FOUND:
          return rejectWithValue(MSG_ERROR_NOT_FOUND);
        default:
          return rejectWithValue(MSG_ERROR);
      }
    }
  }
);

export const byPassMyAccountAsync = createAsyncThunk(
  'auth/by-pass-myaccount',
  async (_, { rejectWithValue }) => {
    const url = `${API_LMI_USER}/bypassmyaccount?key=${BYPASS_KEY}`;
    try {
      const byPassUrl = await axios.get(url);
      return byPassUrl.data;
    } catch (err) {
      const status = err ? err.status : null;
      switch (status) {
        case StatusCodes.NOT_FOUND:
          return rejectWithValue(MSG_ERROR_NOT_FOUND);
        default:
          return rejectWithValue(MSG_ERROR);
      }
    }
  }
);

export const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      auth.logout();
      window.location.replace('/');
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    openResetPasswordModal: (state, { payload }) => {
      state.resetPasswordModalOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(loginAsync.rejected, (state, { payload }) => {
        state.loginStatus = 'idle';
        message.error(payload);
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        auth.login(
          payload.token,
          payload.user.personName.trim()
            ? payload.user.personName
            : payload.user.userName,
          payload.expiry
        );
        state.loginStatus = 'idle';
        state.user = payload;
      })
      .addCase(loginWithEncryptedDataAsync.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(loginWithEncryptedDataAsync.rejected, (state, { payload }) => {
        state.loginStatus = 'idle';
        message.error(payload);
      })
      .addCase(loginWithEncryptedDataAsync.fulfilled, (state, { payload }) => {
        auth.login(
          payload.token,
          payload.user.personName.trim()
            ? payload.user.personName
            : payload.user.userName,
          payload.expiry
        );
        state.loginStatus = 'idle';
        state.user = payload;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.resetPasswordStatus = 'loading';
      })
      .addCase(resetPasswordAsync.rejected, (state, { payload }) => {
        state.resetPasswordStatus = 'error';
        message.error(payload);
      })
      .addCase(resetPasswordAsync.fulfilled, (state, { payload }) => {
        state.resetPasswordStatus = 'idle';
        state.resetPasswordModalOpen = false;
        message.success(payload);
      })
      .addCase(fetchUserAsync.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(fetchUserAsync.rejected, (state, { payload }) => {
        state.loginStatus = 'error';
        message.error(payload);
      })
      .addCase(fetchUserAsync.fulfilled, (state, { payload }) => {
        state.loginStatus = 'idle';
        state.user = payload;
        auth.login(
          payload.token,
          payload.user.personName || payload.user.userName,
          payload.expiry
        );
      })
      .addCase(byPassMyAccountAsync.fulfilled, (state, { payload }) => {
        state.byPassUrl = payload;
      });
  },
});

export const { logout, setUser, openResetPasswordModal } = loginSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectIsUserClient = (state) =>
  state.auth.user?.user?.roles?.some((role) => role.roleName === 'Client');
export const selectIsUserAdmin = (state) =>
  state.auth.user?.user?.roles?.some(
    (role) => role.roleName === 'Administrator'
  );
export default loginSlice.reducer;
